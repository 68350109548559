import React, { useMemo } from 'react';
import globalHelper from '../../helpers/functions/global_helper';
import { Button, Image, Popover, Space } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

const MasterHeader = ({ is_home, }) => {
    const HeaderMenu = (props) => {
        const { direction, float, textColor, size, } = props;
        return (
            <>
                <Space direction={direction} size={size} style={{alignItems:'center', justifyContent:'center', float : float, height : '100%', }}>
                    <div>
                        <a href="/projects" style={{ color : textColor, fontWeight : 500, fontSize : 18, }}>
                            <span>Our Projects</span>
                        </a>
                    </div>
                    <div>
                        <a href="/service" style={{ color : textColor, fontWeight : 500, fontSize : 18, }}>
                            <span>Our Services</span>
                        </a>
                    </div>
                    <div>
                        <a href="/about" style={{ color : textColor, fontWeight : 500, fontSize : 18, }}>
                            <span>About Us</span>
                        </a>
                    </div>
                    <div>
                        <a href="/contact" style={{ color : textColor, fontWeight : 500, fontSize : 18, }}>
                            <span>Contact Us</span>
                        </a>
                    </div>
                </Space>
            </>
        )
    }

    return (
        <>
            <div style={{ display : 'flex', alignContent : 'center', }}>
                <div>
                    <a href='/'>
                        <Image preview={false} src={is_home ? '/logos/logo_evron_white.png' : '/logos/logo_evron.png'} width={120} />
                    </a>
                </div>

                {
                    globalHelper?.isMobileScreen() ?
                    (
                        <>
                            <div style={{ marginLeft : 'auto', }}>
                                <Popover
                                // arrowPointAtCenter
                                // visible={true}
                                trigger={'click'}
                                placement={'bottomLeft'}
                                overlayStyle={{paddingTop:0, }}
                                overlayInnerStyle={{borderRadius:6, padding: '10px 20px'}}
                                content={
                                    <>
                                        <HeaderMenu size={20} float="unset" direction="vertical" textColor="#000" />
                                    </>
                                }
                                >
                                    <Button icon={<MenuOutlined />} type="text" style={{background:'transparent', color : (is_home ? '#fff' : '#000') ,}} />
                                </Popover>
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div style={{ marginLeft : 'auto', }}>
                                <HeaderMenu size={30} float="right" direction="horizontal" textColor={(is_home ? '#fff' : '#000')} />
                            </div>
                        </>
                    )
                }
            </div>
            
        </>
    );
}

export default MasterHeader;