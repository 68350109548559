import React from 'react';
import Masonry from 'react-responsive-masonry';
import globalHelper from '../../../helpers/functions/global_helper';
import { Card, Skeleton, Space } from 'antd';

const ProjectListLoader = () => {
    const { innerWidth } = window;
    
    return (
        <>
            <Masonry columnsCount={(innerWidth > 1024 ? 4 : (innerWidth <= 500 ? 1 : innerWidth <= 700 ? 2 : 3))} gutter={globalHelper?.isMobileScreen() ? '20px' : "40px"}>
                {[...Array(12)].map((x, i) => {
                    const randomHeight = Math.floor(Math.random() * (350 - 120) + 120);
                    return (
                        <div key={i}>
                            <Card
                            className='shadow masonry-item'
                            style={{borderRadius: 12, width:'100%', border : 'none',}}
                            styles={{ body : 12, }}
                            cover={
                                <Skeleton.Input active block size={'large'} style={{width:'100%', height:randomHeight, borderRadius: '12px 12px 0 0'}}/>
                            }
                            >
                                <div style={{textAlign:'start'}}>
                                    <div>
                                        <Skeleton.Input active block style={{ maxHeight : 16, minHeight : 16,}} />
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', marginTop:8}}>
                                        <div>
                                            <Skeleton.Input active style={{ maxHeight : 16, minWidth: 80, width:80}} />
                                            <Skeleton.Input active block style={{ maxHeight : 16}} />
                                            <Skeleton.Input active block style={{ maxHeight : 16}} />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )
                })}
            </Masonry>
        </>
    );
}

export default ProjectListLoader;