import React from 'react';
import ProjectDetail from './components/project_detail';
import { useParams } from 'react-router-dom';

const ProjectDetailIndex = () => {
    const { project_id, } = useParams();
    return (
        <>
            <ProjectDetail project_id={project_id} />
        </>
    );
}

export default ProjectDetailIndex;