import Supabase from "./supabase";

class Customer extends Supabase {
    id;
    name;
    image;
    sequence;
    image_url;

    constructor(id, name, image, sequence, image_url){
        super();
        this.id = id;
        this.name = name;
        this.image = image;
        this.sequence = sequence;
        this.image_url = image_url;
    }

    async fetchCustomers(){
        const { data, error } = await this.supabase
        .from('customers')
        .select(`id, name, image, sequence`);

        const customers = data?.map(async (customer, index) => {
            const { id, name, image, sequence, } = customer;
            const image_url = await this.fetchImage(image);

            return new Customer(id, name, image, sequence, image_url);
        })

        return Promise.all(customers) ?? [];
    }

    async fetchImage(image){
        const { data, error } = this.supabase
        .storage
        .from('customers')
        .getPublicUrl(`${image}`);
        
        return data?.publicUrl;
    }
}

export default Customer;