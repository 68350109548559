import React from 'react';
import ContactLayout from './components/contact_layout';

const ContactIndex = () => {
    return (
        <>
            <ContactLayout />
        </>
    );
}

export default ContactIndex;