import React, { useEffect, useState } from 'react';
import globalHelper from '../../../helpers/functions/global_helper';
import Project from '../../../helpers/classes/project';
import { Row, Col, Image, Space, } from 'antd';
import HeaderItem from '../../../helpers/components/header_item';
import ProjectDetailLoader from './project_detail_loader';

const ProjectDetail = ({ project_id, }) => {
    const { innerWidth, innerHeight, } = window;
    const [state, setState] = useState({
        loading : false,
        project : null,

        selected_image : null,
    })

    const services = [{
        title : 'Construction',
        image : '/services/construct.svg',
        description : 'Our people bring their technical knowledge, experience, and resourcefulness to the delivery of our construction services.',
    },{
        title : 'Design',
        image : '/services/design.svg',
        description : 'The design build team works under a single contract with the project owner to provide design and construction services.',
    },{
        title : 'Custom Furnitures',
        image : '/services/furniture.svg',
        description : 'We created If you have a fine piece of antique and modern furniture.',
    },]

    const fetchProject = async () => {
        setState(state => ({...state, loading : true, }));
        const ProjectClass = new Project(project_id);
        const project = await ProjectClass?.fetchProjectById();

        setTimeout(() => {
            setState(state => ({...state, project : project, loading : false, }));
        }, 500);
        
    }

    useEffect(() => {
        fetchProject();
    }, [])

    return (
        <>
            <HeaderItem>
                {
                    state?.loading ?
                    (
                        <>
                            <ProjectDetailLoader />
                        </>
                    )
                    :
                    (
                        <>
                            <div style={{ paddingBlock : 24,}}>
                                <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                                    {state?.project?.title}
                                </span>
                            </div>

                            <div>
                                {/* image */}
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} md={12} lg={14} xl={14} span={14}>
                                        <div style={{ textAlign : 'start', }}>
                                            <Image
                                            preview={false}
                                            src={state?.project?.image_url}
                                            style={{ borderRadius : 12, minHeight : `calc(${innerHeight}px / 1.3)`, objectFit : 'cover', }}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={24} md={12} lg={10} xl={10} span={10}>
                                        <div>
                                            <Space direction='vertical' size={20} style={{ width : '100%', }}>
                                                {
                                                    state?.project?.sub_images?.length > 0 && (
                                                        <>
                                                            <div>
                                                                <div style={{ textAlign : 'start',}}>
                                                                    <span style={{ fontWeight : 500, fontSize : 18, }}>More Images</span>
                                                                </div>
                                                                <div style={{ marginTop : 12, }}>
                                                                    <Row gutter={[6, 6]}>
                                                                        {
                                                                            state?.project?.sub_images?.map((project_image, index) => {
                                                                                return (
                                                                                    <Col span={6} key={index}>
                                                                                        <Image src={project_image} style={{ borderRadius : 8, }} />
                                                                                    </Col>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                                <div style={{ textAlign : 'start',}}>
                                                    <div>
                                                        <span style={{ fontWeight : 500, fontSize : 18, }}>Description</span>
                                                    </div>
                                                    <div style={{ marginTop : 12, }}>
                                                        <span style={{ fontSize : 16, }}>{state?.project?.description}</span>
                                                    </div>
                                                </div>

                                                {/* What we done */}
                                                {
                                                    services?.length > 0 && 
                                                    (
                                                        <>
                                                            <div>
                                                                <div style={{ textAlign : 'start', }}>
                                                                    <span style={{ fontWeight : 500, fontSize : 18, }}>What have we done</span>
                                                                </div>
                                                                <div style={{ marginTop : 12, textAlign : 'start', }}>
                                                                    <div style={{ background : '#edf2f7', padding : 24, borderRadius: 12, }}>
                                                                        <Space direction='vertical' size={24}>
                                                                            {
                                                                                services?.map((service, index) => {
                                                                                    const { image, title, description, } = service;
                                                                                    return (
                                                                                        <div key={index}>
                                                                                            <Space size={16}>
                                                                                                <div>
                                                                                                    <Image preview={false} src={image} style={{ width : 32, height : 32, }} />
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span style={{ fontFamily : 'inter', fontWeight : 600, fontSize : 18, }}>
                                                                                                        {title}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </Space>
                                                                                            <div style={{ marginTop : 4, }}>
                                                                                                <span style={{ fontFamily : 'inter', color : '#595959', fontSize : 16, }}>
                                                                                                    {description}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            
                                                                        </Space>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </Space>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )
                }
            </HeaderItem>
        </>
    );
}

export default ProjectDetail;