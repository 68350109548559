import { Layout } from 'antd';
import React from 'react';
import MasterFooter from './master_footer';
import MasterHeader from './master_header';
import { useLocation } from 'react-router-dom';
import globalHelper from '../../helpers/functions/global_helper';

const { Header, Content, Sider, Footer, } = Layout;
const MasterLayout = ({ children, }) => {
    const location = useLocation();
    const is_home = location?.pathname === '/';
    return (
        <>
            <Layout>
                <Layout>
                    <Header style={{ background : 'transparent', zIndex: 100, width: '100%', paddingInline : (globalHelper?.isMobileScreen() ? 12 : 42), }}>
                        <MasterHeader is_home={is_home} />
                    </Header>
                    <Content className='main-content'>
                        <div className='content'>
                            {children}
                        </div>
                    </Content>
                    <Footer style={{ background : '#0d2c49', paddingBlock :40, }}>
                        <MasterFooter />
                    </Footer>
                </Layout>
            </Layout>
        </>
    );
}

export default MasterLayout;