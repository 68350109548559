import React from 'react';
import globalHelper from '../../../helpers/functions/global_helper';
import { Col, Divider, Image, Row, Space } from 'antd';
import ServiceList from '../../service/components/service_list';

const HomeService = () => {
    return (
        <>
            <div style={{ background : '#f2f3f9', padding : 40, }}>
                <div>
                    <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                        Our Services
                    </span>
                </div>
                <div style={{ marginTop : 40, ...(!globalHelper?.isMobileScreen && {paddingInline : 40, })}}>
                    {/* <Row gutter={[40, 40]}>
                    {
                        services?.map((service, index) => {
                            const { title, image, description, } = service;
                            return (
                                <Col xs={24} sm={12} md={8} span={8} key={index}>
                                    <div style={{ textAlign : 'start', }}>
                                        <Space size={20}>
                                            <div>
                                                <Image preview={false} src={image} style={{ width : 40, height : 40, }} />
                                            </div>
                                            <div>
                                                <span style={{ fontFamily : 'inter', fontSize : 20, fontWeight : 600, }}>
                                                    {title}
                                                </span>
                                            </div>
                                        </Space>
                                        <div style={{ marginTop : 12, }}>
                                            <span style={{ fontFamily : 'inter', fontSize : 16, fontWeight: 500, color : '#595959' }}>
                                                {description}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                    </Row> */}

                    <ServiceList />
                </div>
            </div>
        </>
    );
}

export default HomeService;