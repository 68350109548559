import React from 'react';
import ContactDetail from './contact_detail';
import { Col, Row, Space } from 'antd';
import { MailFilled, PhoneFilled } from '@ant-design/icons';

const ContactInfo = () => {
    return (
        <>
            <div>
                <div style={{ marginTop : 24, }}>
                    <Row gutter={[42, 42]} span={24}>
                        <Col xs={24} sm={12} lg={8}>
                            <ContactDetail title={'Address'} description={(
                                <>
                                    <div>
                                        Evron Space Solution Sdn Bhd <br />
                                        18-35E Gurney Tower <br />
                                        Persiaran Gurney <br />
                                        10250 George Town <br />
                                        Pulau Pinang
                                    </div>
                                </>
                            )} />
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                            <ContactDetail title={'Point Of Contact'} description={(
                                <>
                                    <div>
                                        <div>
                                            <span>Mr. Noew Ching Yik</span>
                                        </div>
                                        <div style={{ marginTop : 6, }}>
                                            <div>
                                                <Space>
                                                    <MailFilled />
                                                    <div>
                                                        <span>evron.engineering@gmail.com</span>
                                                    </div>
                                                </Space>
                                            </div>
                                        </div>
                                        <div style={{ marginTop : 6, }}>
                                            <div>
                                                <Space>
                                                    <PhoneFilled />
                                                    <div>
                                                        <span>+6016-2060413</span>
                                                    </div>
                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )} />
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                            <ContactDetail title={'Operating Hours'} description={(
                                <>
                                    <span>Monday to Friday : 9:00 AM - 6.00 PM</span>
                                </>
                            )} />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default ContactInfo;