import React from 'react';
import globalHelper from '../../../helpers/functions/global_helper';
import ProjectList from './project_list';
import HeaderItem from '../../../helpers/components/header_item';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import ContainerComponent from '../../../helpers/components/container_component';
import { Divider } from 'antd';

const ProjectLayout = () => {
    return (
        <>
            <HeaderItem>
                <ContainerComponent span={24} top={24} bottom={24}>
                    <EntranceMotion>
                        <div>
                            <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                                Our Projects
                            </span>
                        </div>
                    </EntranceMotion>
                    <div style={{ marginTop : (globalHelper?.isMobileScreen() ? 24 : 42), }}>
                        <ProjectList />
                    </div>
                </ContainerComponent>
            </HeaderItem>
        </>
    );
}

export default ProjectLayout;