import React from 'react';
import AbsoluteItem from '../../../helpers/components/absolute_item';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import globalHelper from '../../../helpers/functions/global_helper';
import { Button, Col, Row } from 'antd';

const HomeIntro = () => {
    const { innerWidth, innerHeight, } = window;
    const images = ['/banners/image_1.webp', '/banners/image_2.webp', '/banners/image_3.webp', '/banners/image_4.jpg']
    return (
        <>
            <div style={{ position : 'relative', }}>
                <div>
                    <AbsoluteItem style={{ transform: 'translate(-50%, -50%)', minWidth : '60%', }}>
                        {/* Content for the div outside the Row */}
                        <div>
                            <EntranceMotion>
                                <div>
                                    <div>
                                        <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '2rem' : '3rem'), color : '#fff', fontWeight : 900, }}>
                                            BUILDING GREAT THINGS
                                        </span>
                                    </div>
                                    <div>
                                        <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1rem' : '1.5rem'), color : '#fff', opacity : 0.8, fontWeight : 'bold', }}>
                                            If you dream of designing a new home that takes full advantage of the unique geography and views of land that you love
                                        </span>
                                    </div>

                                    <div style={{ marginTop : 12, }}>
                                        <a href="/service">
                                            <Button size='large' type='primary'>
                                                <span style={{ fontWeight : 500, }}>What We Do</span>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </EntranceMotion>
                        </div>
                    </AbsoluteItem>
                </div>
                <Row>
                    {
                        images?.map((image, index) => {
                            return (
                                <Col span={12} key={index}>
                                    <div
                                    style={{
                                        backgroundImage : `url(${image})`,
                                        backgroundRepeat : 'no-repeat',
                                        backgroundPosition : 'center',
                                        backgroundSize : 'cover',
                                    }}
                                    >
                                        <div className='card-overlay' style={{  width : '100%', height : `calc((${innerHeight}px) / 2)`,}} />
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </>
    );
}

export default HomeIntro;