import { EnvironmentOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Image, Row, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ContainerComponent from '../../helpers/components/container_component';

const MasterFooter = () => {
    return (
        <>
            <ContainerComponent span={24}>
                <div style={{ textAlign : 'start', }}>
                    <Row justify={'center'} gutter={[40, 20]}>
                        <Col xs={24} lg={8} span={8}>
                            <div>
                                <div style={{textAlign:'left', display:'flex', alignItems:'center',}}>
                                    <Image preview={false} src='/logos/logo_evron_white.png' width={180} />
                                </div>
                                <div style={{ marginTop : 12, }}>
                                    <span style={{color:'rgba(255, 255, 255, 0.75)',}}>
                                    As an interior design specialist, we are willing to share the latest and modern design with our dearest customers. Our vision is to become a comprehensive interior design company that is able to fulfil different customer’s needs.
                                    </span>
                                </div>
                                <div style={{ marginTop : 20, }}>
                                    {/* <Space size={20}>
                                        <div>
                                            <a href='https://www.facebook.com/solecar.my' target={"_blank"} rel="noreferrer">
                                                <Image src='/icons/facebook.svg' preview={false} style={{height:40}} />
                                            </a>
                                        </div>
                                        <div>
                                            <a href='https://www.instagram.com/solecar.my/' target={"_blank"} rel="noreferrer">
                                                <Image src='/icons/instagram.svg' preview={false} style={{height:40}} />
                                            </a>
                                        </div>
                                        <div>
                                            <a href='https://bit.ly/3SFnJod' target={"_blank"} rel="noreferrer">
                                                <Image src='/icons/tiktok.svg' preview={false} style={{height:30}} />
                                            </a>
                                        </div>
                                        <div>
                                            <a href='https://bit.ly/3T2ieQw' target={"_blank"} rel="noreferrer">
                                                <Image src='/icons/xhs.svg' preview={false} style={{height:30}} />
                                            </a>
                                        </div>
                                    </Space> */}
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} lg={4} span={4}>
                            <div style={{color:'#fff'}}> 
                                <div style={{color:'#fff', fontSize:'1.2rem', textAlign:'left'}}>
                                    <span>Services</span>
                                </div>
                                <div style={{marginTop:20}}>
                                    <Space direction='vertical'>
                                        <div>
                                            <span>Maintenance Solutions</span>
                                        </div>
                                        <div>
                                            <span>Renovation Solutions</span>
                                        </div>
                                    </Space>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} lg={6} span={6}>
                            <div style={{color:'#fff'}}> 
                                <div style={{color:'#fff', fontSize:'1.2rem', textAlign:'left'}}>
                                    <span>Contact Us</span>
                                </div>
                                <div style={{marginTop:20}}>
                                    <Space direction='vertical'>
                                        <div>
                                            <Space>
                                                <PhoneOutlined />
                                                <div>
                                                    <span>
                                                        <a href="https://wa.me/60162060413" style={{marginLeft:4}}>+6016-2060413</a>
                                                    </span>
                                                </div>
                                            </Space>
                                        </div>
                                        <div>
                                            <Space align='start'>
                                                <EnvironmentOutlined />
                                                <div>
                                                    Evron Space Solution Sdn Bhd <br />
                                                    18-35E Gurney Tower <br />
                                                    Persiaran Gurney <br />
                                                    10250 George Town <br />
                                                    Pulau Pinang
                                                </div>
                                            </Space>
                                        </div>
                                    </Space>
                                </div>
                            </div>
                        </Col>

                        <Col  xs={24} lg={4} span={4}>
                            <div style={{color:'#fff'}}> 
                                <div style={{color:'#fff', fontSize:'1.2rem', textAlign:'left'}}>
                                    <span>Operating Hours</span>
                                </div>
                                <div style={{marginTop:20}}>
                                    <Space direction='vertical'>
                                        <div>
                                            <span>Monday to Friday</span>
                                        </div>
                                        <div>
                                            <span>9:00 AM to 6:00 PM</span>
                                        </div>
                                    </Space>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </ContainerComponent>
        </>
    );
}

export default MasterFooter;