import React from 'react';
import AuthRouter from './routes/auth.router';

const Auth = () => {
    return (
        <>
            <AuthRouter />
        </>
    );
}

export default Auth;