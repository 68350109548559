import React from 'react';
import ProjectLayout from './components/project_layout';
import ContainerComponent from '../../helpers/components/container_component';

const ProjectIndex = () => {
    return (
        <>
            <ProjectLayout />
        </>
    );
}

export default ProjectIndex;