import React from 'react';
import HeaderItem from '../../../helpers/components/header_item';
import ContainerComponent from '../../../helpers/components/container_component';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import globalHelper from '../../../helpers/functions/global_helper';
import AboutSummary from './about_summary';
import { Col, Divider, Row, Space } from 'antd';
import AboutTitle from './about_title';
import AboutWhyChooseUs from './about_why_choose_us';
import AboutMissionVision from './about_mission_vision';

const AboutLayout = () => {
    return (
        <>
            <HeaderItem>
                <ContainerComponent top={20} span={24}>
                    <EntranceMotion>
                        <div>
                            <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                                About Us
                            </span>
                        </div>
                    </EntranceMotion>

                    <div style={{ marginTop : (globalHelper?.isMobileScreen() ? 24 : 42), }}>
                        <Row justify={'center'}>
                            <Col xs={24} sm={18}>
                                <AboutSummary />
                            </Col>
                        </Row>
                    </div>
                </ContainerComponent>
            </HeaderItem>
            <Divider />
            <div>
                <AboutMissionVision />
            </div>
            <Divider />
            <div style={{ paddingBottom : 42, }}>
                <AboutWhyChooseUs />
            </div>
        </>
    );
}

export default AboutLayout;