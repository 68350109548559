import React from 'react';

const AbsoluteItem = ({ topPercentage, leftPercentage, style, children, }) => {
    return (
        <>
            <div className="outside-div"
            style={{
                ...style,
                position: 'absolute',
                top: `${topPercentage ?? 50}%`,
                left: `${leftPercentage  ?? 50}%`,
                // transform: 'translate(-50%, -50%)',
                zIndex: 100,
                }}
            >
                {children}
            </div>
        </>
    );
}

export default AbsoluteItem;