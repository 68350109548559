import React from 'react';
import AboutLayout from './components/about_layout';

const AboutIndex = () => {
    return (
        <>
            <AboutLayout />
        </>
    );
}

export default AboutIndex;