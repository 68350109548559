import React, { useState } from 'react';
import HeaderItem from '../../../helpers/components/header_item';
import ContainerComponent from '../../../helpers/components/container_component';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import globalHelper from '../../../helpers/functions/global_helper';
import { Row, Col, Form, Divider, Button, Space, Image, message } from 'antd';
import ContactForm from './contact_form';
import ContactInfo from './contact_info';
import Contact from '../../../helpers/classes/contact';

const ContactLayout = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
    })

    const submitForm = async () => {
        setState(state => ({...state, loading : true, }));

        const { first_name, last_name, email, phone_number, message : text_message, } = form?.getFieldsValue();

        const ContactClass = new Contact(first_name, last_name, email, phone_number, text_message);
        const sendContactFormResponse = await ContactClass.sendContactResponse()
        .catch(err => setState(state => ({...state, laoding : false,})));

        // success message
        message.success("Message sent")

        setState(state => ({...state, loading : false, }));
    }
    
    return (
        <>
            <HeaderItem>
                <ContainerComponent span={24} top={24} bottom={24}>
                    <div>
                        <EntranceMotion>
                            <div>
                                <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                                    Contact Our Team
                                </span>
                            </div>
                        </EntranceMotion>
                        <EntranceMotion delay={(1 * 0.1)} style={{ marginTop : 8, }}>
                            <Row justify={'center'}>
                                <Col xs={24} sm={18}>
                                    <div>
                                        <span style={{ color : 'var(--secondary-text-color)', fontSize : 18, }}>We value your inquiries and look forward to assisting you with your maintenance and renovation needs. Feel free to reach out to us through any of the following channels</span>
                                    </div>
                                </Col>
                            </Row>
                        </EntranceMotion>
                    </div>

                    <div style={{ marginTop : (globalHelper?.isMobileScreen() ? 24 : 42), }}>
                        <Row justify={'center'} gutter={[24, 24]}>
                            <Col xs={24} sm={24} md={12} lg={9} span={9}>
                                <a href="https://wa.me/60162060413">
                                    <div style={{ background : 'var(--secondary-button-color)', padding : 24, borderRadius : 12, }}>
                                        <Space direction='vertical'>
                                            <div>
                                                <Image preview={false} src={'/icons/whatsapp.svg'} style={{ width : 40, height : 40, }} />
                                            </div>
                                            <div>
                                                <span style={{ fontSize : 16, fontWeight : 'bold', color : '#000', }}>WhatsApp Us</span>
                                            </div>
                                            <div>
                                                <span style={{ fontSize : 16, fontWeight : 'bold', color : '#000', }}>+6016-2060413</span>
                                            </div>
                                        </Space>
                                    </div>
                                </a>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={9} span={9}>
                                <a href='mailto:evron.engineering@gmail.com'>
                                    <div style={{ background : 'var(--secondary-button-color)', padding : 24, borderRadius : 12, }}>
                                        <Space direction='vertical'>
                                            <div>
                                                <Image preview={false} src={'/icons/mail.svg'} style={{ width : 40, height : 40, }} />
                                            </div>
                                            <div>
                                                <span style={{ fontSize : 16, fontWeight : 'bold', color : '#000', }}>Email Us</span>
                                            </div>
                                            <div>
                                                <span style={{ fontSize : 16, fontWeight : 'bold', color : '#000', }}>evron.engineering@gmail.com</span>
                                            </div>
                                        </Space>
                                    </div>
                                </a>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ marginTop : 62, }}>
                        <Row gutter={[12, 12]} justify={'center'}>
                            <Col xs={24} sm={18}>
                                <div>
                                    <ContactInfo />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ marginTop : 62, }}>
                        <div>
                            <Row gutter={[42, 12]} justify={'center'}>
                                <Col xs={24} sm={8} span={12}>
                                    <div style={{ textAlign : 'start', }}>
                                        <div>
                                            <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                                                Talk to our team
                                            </span>
                                        </div>
                                        <div style={{ marginTop : 12, }}>
                                            <span style={{ color : 'var(--secondary-text-color)', fontSize : 18, }}>
                                            For general inquiries, service requests, or to schedule a consultation, please don't hesitate to contact us. We are committed to providing prompt and reliable assistance to meet your maintenance and renovation needs.
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={10}>
                                    <div>
                                        <Form
                                        form={form}
                                        layout='vertical'
                                        size='large'
                                        onFinish={submitForm}
                                        >
                                            <ContactForm form={form} />

                                            <Form.Item>
                                                <Button loading={state?.loading} onClick={() => form.submit()} size='large' type='primary' style={{ width : '100%', fontSize : 14, }}>
                                                    Send Message
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ContainerComponent>
            </HeaderItem>
        </>
    );
}

export default ContactLayout;