import { Card, Col, Image, Row, Space } from 'antd';
import React from 'react';
import globalHelper from '../../../helpers/functions/global_helper';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const AboutTitle = ({ title, description, image, sequence, }) => {
    return (
        <>
            <EntranceMotion delay={(sequence * 0.1)} style={{ height : '100%', }}>
                    <div style={{ textAlign : 'start', }}>
                        <Space size={20}>
                            <div>
                                <Image preview={false} src={image} style={{ width : 40, height : 40, }} />
                            </div>
                            <div>
                                <span style={{ fontFamily : 'inter', fontSize : 24, fontWeight : 'bold', }}>
                                    {title}
                                </span>
                            </div>
                        </Space>
                        <div style={{ marginTop : 12, }}>
                            <span style={{ fontFamily : 'inter', fontSize : 16, color : 'var(--secondary-text-color)', }}>
                                {description}
                            </span>
                        </div>
                    </div>
            </EntranceMotion>
        </>
    );
}

export default AboutTitle;