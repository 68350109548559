import { Col, Image, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Customer from '../../../helpers/classes/customer';
import CustomerLoader from '../loaders/customer_loader';

const HomeCustomer = () => {
    const [state, setState] = useState({
        loading : false,
        clients : [],
    });

    const fetchClients = async () => {
        setState(state => ({...state, loading : true, }));
        const CustomerClass = new Customer();
        const customers = await CustomerClass.fetchCustomers();

        setState(state => ({...state, loading : false, clients : customers, }));
    }

    useEffect(() => {
        fetchClients();
    }, [])

    return (
        <>
            {
                state?.loading ? (
                    <>
                        <CustomerLoader />
                    </>
                )
                :
                (
                    <>
                        <div style={{ padding : 40, }}>
                            <Row justify={'center'}>
                                <Col span={20}>
                                    <Row gutter={[42, 42]} align={'middle'} justify={'center'}>
                                    {
                                        state?.clients?.sort((a, b) => a?.sequence - b?.sequence)?.map((client, index) => {
                                            const { image_url, } = client;
                                            return (
                                                <Col key={index} xs={12} sm={8} md={4} span={4}>
                                                    <Image preview={false} src={image_url} style={{ maxWidth : 120, borderRadius : 12, }} />
                                                </Col>
                                            )
                                        })
                                    }
                                    </Row>
                                </Col>
                            </Row>
                            
                        </div>
                    </>
                )
            }
        </>
    );
}

export default HomeCustomer;