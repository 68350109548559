import { Col, Row, Skeleton } from 'antd';
import React from 'react';

const CustomerLoader = () => {
    return (
        <>
            <div style={{ padding : 40, }}>
                <Row gutter={[20, 20]} align={'middle'} justify={'center'}>
                    {[...Array(7)].map((x, i) => {
                        return (
                            <Col key={i} xs={12} sm={8} md={3} span={3}>
                                <Skeleton.Input active block size={'large'} style={{minWidth: 120, height: 120, borderRadius: 8}}/>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </>
    );
}

export default CustomerLoader;