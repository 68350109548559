import { Space } from 'antd';
import React from 'react';

const AboutSummary = () => {
    return (
        <>
            <div style={{ textAlign : 'start', color : 'var(--secondary-text-color)', fontSize : 18, }}>
                <Space size={24} direction='vertical' style={{ width : '100%' }}>
                    <div>
                        <span>Formerly known as Evron Engineering, Evron Space Solution Sdn Bhd has evolved to meet the changing needs of businesses across Malaysia. Founded in 2021 by Mr. Noew Ching Yik, our company initially focused on Maintenance Solutions Management. However, recognizing the demand for comprehensive renovation services post-pandemic, we expanded our offerings to include renovation solutions tailored to businesses' requirements.</span>
                    </div>
                    <div>
                        <span>Our journey began amidst the challenges of restoring operational spaces in the wake of prolonged lockdowns. With a keen understanding of mechanical engineering maintenance solutions and a commitment to excellence in Sales & Marketing and Project Management, we embarked on a mission to redefine industry standards.</span>
                    </div>
                    <div>
                        <span>Driven by a passion for innovation and guided by our founder's expertise, we collaborate with industry leaders to enhance our capabilities. This partnership allows us to scale our operations rapidly and meet diverse client needs efficiently.</span>
                    </div>
                    <div>
                        <span>Today, Evron Space Solution is synonymous with reliability, professionalism, and dedication. Whether it's preventive maintenance, repairs, or comprehensive renovation projects, we strive to exceed expectations at every turn. Our vision is clear: to be the premier provider of maintenance and renovation solutions in Malaysia, setting new standards of excellence and innovation in the industry.</span>
                    </div>
                    <div>
                        <span>With Evron Space Solution, you can trust that your premises are in capable hands. We are committed to ensuring your business reaches its full potential by delivering tailored solutions that drive tangible results.</span>
                    </div>
                </Space>
            </div>
        </>
    );
}

export default AboutSummary;