import React from 'react';
import ServiceLayout from './components/service_layout';

const ServiceIndex = () => {
    return (
        <>
            <ServiceLayout />
        </>
    );
}

export default ServiceIndex;