import { Card, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import Masonry from 'react-responsive-masonry';
import globalHelper from '../../../helpers/functions/global_helper';
import Project from '../../../helpers/classes/project';
import ProjectListLoader from './project_list_loader';

const ProjectList = () => {
    const { innerWidth, } = window;
    const [state, setState] = useState({
        loading : false,
        projects : [],
    })

    const fetchProjects = async () => {
        setState(state => ({...state, loading : true, }));
        const ProjectClass = new Project();
        const projects = await ProjectClass.fetchProjects().catch(err => console.error(err));

        setTimeout(() => {
            setState(state => ({...state, projects : projects, loading : false, }));
        }, 500);
        
    }

    useEffect(() => {
        fetchProjects();
    }, [])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            for(const entry of entries){
                if(entry.isIntersecting){
                    entry.target.classList.add('show');
                }
            }
        });
    
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));
    }, [state?.projects]);

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <ProjectListLoader />
                    </>
                )
                :
                (
                    <>
                        <Masonry columnsCount={(innerWidth > 1024 ? 4 : (innerWidth <= 500 ? 1 : innerWidth <= 700 ? 2 : 3))} gutter={globalHelper?.isMobileScreen() ? '20px' : "40px"}>
                        {
                            state?.projects?.sort((a, b) => a?.sequence - b?.sequence)?.map((project, index) => {
                                const { id, title, image_url, description, } = project;
                                return (
                                    // <a key={index} href={`/project/${id}`}>
                                        <Card
                                        key={index}
                                        className='card-shadow'
                                        // style={{ border : 'none', '--order' : (index + 1) }}
                                        cover={<Image preview={false} src={image_url} style={{ minHeight : 180, maxHeight : 320, objectFit : 'cover', }} />}
                                        >
                                            <div style={{ textAlign : 'start', }}>
                                                <div>
                                                    <span style={{ fontFamily : 'inter', fontSize : 18, fontWeight : 600, }}>
                                                        {title}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{ fontFamily : 'inter', fontSize : 16, }}>
                                                        {description}
                                                    </span>
                                                </div>
                                            </div>
                                        </Card>
                                    // </a>
                                )
                            })
                        }
                    </Masonry>
                    </>
                )
            }
        </>
    );
}

export default ProjectList;