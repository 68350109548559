import React from 'react';

const ContactDetail = ({ title, description, }) => {
    return (
        <>
            <div style={{ textAlign : 'start',}}>
                <div>
                    <span style={{ fontSize : 20, fontWeight : 500, }}>{title}</span>
                </div>
                <div style={{ marginTop : 6, }}>
                    <span style={{ color : 'var(--secondary-text-color)', fontSize : 16, }}>{description}</span>
                </div>
            </div>
        </>
    );
}

export default ContactDetail;