import React from 'react';
import HomeIntro from './intro';
import HomeService from './service';
import { Space } from 'antd';
import HomeCustomer from './customer';
import HomeProject from './project';
import HomeTestimonial from './testimonial';

const HomeLayout = () => {
    
    return (
        <>
            <Space size={40} direction='vertical'  style={{ width : '100%', }}>
                <div style={{ maxHeight : '100vh' }}>
                    <HomeIntro />
                </div>
                <div>
                    <HomeCustomer />
                </div>
                <div>
                    <HomeService />
                </div>
                <div>
                    <HomeProject />
                </div>
                <div>
                    <HomeTestimonial />
                </div>
            </Space>
        </>
    );
}

export default HomeLayout;