import { Col, Divider, Image, Row, Space } from 'antd';
import React from 'react';

const ServiceList = () => {
    const service_groups = [{
        name : "Maintenance Solutions",
        services : [{
            title : 'Preventive Maintenance (PM)',
            image : '/services/construct.svg',
            description : 'We provide scheduled inspections, servicing, and adjustments of building systems and components to ensure they operate efficiently and reach their planned service life. Our proactive approach helps prevent unexpected breakdowns and costly repairs, saving you time and money in the long run.',
        },{
            title : 'Repair Services',
            image : '/services/design.svg',
            description : "From fixing minor wear and tear to addressing major breakdowns, our experienced team handles all types of repairs promptly and efficiently. Whether it's plumbing, electrical, carpentry, or roofing repairs, we ensure your premises are restored to optimal condition with minimal disruption to your operations.",
        },{
            title : 'Discretionary Maintenance',
            image : '/services/furniture.svg',
            description : "We offer discretionary maintenance services such as flooring replacement, painting, and other aesthetic improvements to enhance the appearance and functionality of your premises. Our skilled craftsmen deliver superior workmanship, ensuring your space reflects your brand identity and meets your aesthetic preferences.",
        },]
    },{
        name : "Renovation Solutions",
        services : [{
            title : 'Complete Renovations',
            image : '/services/plump.svg',
            description : "Whether you're looking to revamp your office space, retail outlet, or commercial premises, we specialize in complete renovation projects tailored to your specific requirements. From conceptualization to execution, we manage every aspect of the renovation process, ensuring a seamless and hassle-free experience for our clients.",
        },{
            title : 'Shop Setup and Refurbishment',
            image : '/services/electric.svg',
            description : "We specialize in setting up new shops and refurbishing existing ones to create inviting and functional spaces that attract customers and drive business growth. From layout design to fixture installation, we transform empty spaces into vibrant retail environments that enhance the customer experience and maximize sales potential.",
        },{
            title : 'Office Renovations',
            image : '/services/wrench.svg',
            description : "Our office renovation services are designed to create productive and inspiring work environments that foster creativity, collaboration, and employee satisfaction. Whether you're expanding your office space, modernizing outdated facilities, or reconfiguring layout designs, we tailor our solutions to align with your business goals and objectives.",
        },]
    }];

    return (
        <>
            <Space direction='vertical' size={24} style={{ width : '100%', }}>
                {
                    service_groups?.map((service_group, index) => {
                        const { name : group_name, services, } = service_group;
                        return (
                            <div key={index}>
                                {
                                    index > 0 &&
                                    (
                                        <>
                                            <Divider />
                                        </>
                                    )
                                }
                                <div style={{ textAlign : 'start', }}>
                                    <span style={{ fontFamily : 'inter', fontSize : 24, fontWeight : 'bold', }}>
                                        {group_name}
                                    </span>
                                </div>
                                <div style={{ marginTop : 42, }}>
                                    <Row gutter={[40, 40]}>
                                    {
                                        services?.map((service, index) => {
                                            const { title, image, description, } = service;
                                            return (
                                                <Col xs={24} sm={12} md={8} span={8} key={index}>
                                                    <div style={{ textAlign : 'start', }}>
                                                        <Space size={20}>
                                                            <div>
                                                                <Image preview={false} src={image} style={{ width : 40, height : 40, }} />
                                                            </div>
                                                            <div>
                                                                <span style={{ fontFamily : 'inter', fontSize : 20, fontWeight : 600, color : 'var(--secondary-text-color)', }}>
                                                                    {title}
                                                                </span>
                                                            </div>
                                                        </Space>
                                                        <div style={{ marginTop : 20, }}>
                                                            <span style={{ fontFamily : 'inter', fontSize : 16, fontWeight: 500, color : '#595959' }}>
                                                                {description}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                    </Row>
                                </div>
                            </div>
                        )
                    })
                }
            </Space>
        </>
    );
}

export default ServiceList;