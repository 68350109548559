import React, { useEffect, useState } from 'react';
import globalHelper from '../../../helpers/functions/global_helper';
import Masonry from "react-responsive-masonry"
import { Card, Image } from 'antd';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import Project from '../../../helpers/classes/project';

const HomeProject = () => {
    const { innerWidth } = window;
    const [state, setState] = useState({
        loading : false,
        projects : [],
    });
    
    const fetchProjects = async () => {
        const ProjectClass = new Project();
        let projects = await ProjectClass.fetchProjects().catch(err => console.error(err));

        // sort out top 4
        projects = projects?.slice(0, 4);
        setState(state => ({...state, projects : projects, }));
        
    }

    useEffect(() => {
        fetchProjects();
    }, [])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            for(const entry of entries){
                if(entry.isIntersecting){
                    entry.target.classList.add('show');
                }
            }
        });
    
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));
    }, [state?.projects])

    return (
        <>
            <div style={{ paddingTop : 20, paddingBottom : 40, paddingInline : 40, }}>
                <div>
                    <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                        Projects
                    </span>
                </div>

                <div style={{ marginTop : 40, }}>
                    <Masonry columnsCount={(innerWidth > 1024 ? 4 : (innerWidth <= 500 ? 1 : innerWidth <= 700 ? 2 : 3))} gutter={globalHelper?.isMobileScreen() ? '20px' : "40px"}>
                        {
                            state?.projects?.map((project, index) => {
                                const { title, image_url, description, } = project;
                                return (
                                    <Card
                                    key={index}
                                    className='card-shadow hidden'
                                    style={{ border : 'none', '--order' : (index + 1) }}
                                    cover={<Image preview={false} src={image_url} style={{ minHeight : 180, maxHeight : 320, objectFit : 'cover', }} />}
                                    >
                                        <div style={{ textAlign : 'start', }}>
                                            <div>
                                                <span style={{ fontFamily : 'inter', fontSize : 18, fontWeight : 600, }}>
                                                    {title}
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{ fontFamily : 'inter', fontSize : 16, }}>
                                                    {description}
                                                </span>
                                            </div>
                                        </div>
                                    </Card>
                                )
                            })
                        }
                    </Masonry>
                </div>
            </div>
        </>
    );
}

export default HomeProject;