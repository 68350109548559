import serviceHelper from "../functions/service_helper";

class Contact{
    first_name;
    last_name;
    email;
    phone_number;
    message;

    constructor(first_name, last_name, email, phone_number, message){
        this.first_name = first_name;
        this.last_name = last_name;
        this.email = email;
        this.phone_number = phone_number;
        this.message = message;
    }

    async sendContactResponse(){
        const query = {
            "entry.523820208" : this.first_name,
            "entry.1834734662" : this.last_name,
            "entry.708217824" : this.email,
            "entry.1665651157" : this.phone_number,
            "entry.789064185" : this.message,
        }

        const queryString = serviceHelper.queryString(query);
        const sendFormResponse = await fetch(
            `https://docs.google.com/forms/d/e/1FAIpQLScWkr2blON0JRJfHYg5Qk6m_pHDgPZ0iUzOp6TYBS8950beGA/formResponse?${queryString}`,
            { headers : { 'Content-Type' : 'text/plain' }, mode : 'no-cors'},
        );

        return sendFormResponse?.status;
    }
}

export default Contact;