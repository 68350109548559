import React from 'react';
import { Skeleton, Row, Col, Image, Space, } from 'antd';

const ProjectDetailLoader = () => {
    const { innerHeight } = window;
    return (
        <>
            <div style={{ textAlign : 'start', paddingBlock : 24,}}>
                <Skeleton.Input active style={{ minWidth : 200, }} />
            </div>

            <div>{/* image */}
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={12} lg={14} xl={14} span={14}>
                        <div style={{ textAlign : 'start', }}>
                            <Skeleton.Input
                            active
                            block
                            size={'large'}
                            style={{ borderRadius : 12, minHeight : `calc(${innerHeight}px / 1.3)`, objectFit : 'cover', }}
                            />
                        </div>
                    </Col>

                    <Col xs={24} md={12} lg={10} xl={10} span={10}>
                        <div>
                            <Space direction='vertical' size={42} style={{ width : '100%', }}>
                                <div>
                                    <Row gutter={[6, 6]}>
                                        {[...Array(4)].map((x, i) => {
                                            return (
                                                <Col span={6} key={i}>
                                                    <Skeleton.Input active block size={'large'} style={{minWidth:100, height: 100, borderRadius: 8}}/>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                                

                                <div style={{ textAlign : 'start',}}>
                                    <div style={{ marginTop : 12, }}>
                                        <Skeleton title={false} active paragraph={{rows:3}} />
                                    </div>
                                </div>

                                {/* What we done */}
                                <div>
                                    <div style={{ marginTop : 12, textAlign : 'start', }}>
                                        <div>
                                            <Space direction='vertical'>
                                                {[...Array(3)].map((x, i) => {
                                                    return (
                                                        <div span={6} key={i}>
                                                            <Space>
                                                                <Skeleton.Input active block size={'large'} style={{minWidth:32, height: 32, borderRadius: 8}}/>
                                                                <Skeleton.Input active />
                                                            </Space>
                                                        </div>
                                                    )
                                                })}
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ProjectDetailLoader;