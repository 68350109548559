import React from 'react';
import globalHelper from '../functions/global_helper';

const HeaderItem = ({ children, }) => {
    return (
        <>
            <div style={{ background : 'linear-gradient(rgba(255, 231, 186,.5),#fff),linear-gradient(90deg,#fff7e6 55%,#ffd8bf)', height : 'calc(var(--header-height) * 2)', }} />
            <div style={{ paddingBottom : 24, paddingInline : (globalHelper?.isMobileScreen() ? 12 : 42), }}>
                {children}
            </div>
        </>
    );
}

export default HeaderItem;