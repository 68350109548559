import React from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './public.router';
import PrivateRoute from './private.router';
import HomeIndex from '../containers/home';
import ProjectIndex from '../containers/project';
import ProjectDetailIndex from '../containers/project/detail';
import AboutIndex from '../containers/about';
import ServiceIndex from '../containers/service';
import ContactIndex from '../containers/contact';

// Page
const AppRouter = () => {
    return (
        <>
            <Switch>
                {/* Home */}
                <PublicRoute component={HomeIndex} path="/" exact restricted={false} />
                {/* Projects */}
                <PublicRoute component={ProjectIndex} path="/projects" exact restricted={false} />
                <PublicRoute component={ProjectDetailIndex} path="/project/:project_id" exact restricted={false} />
                {/* About */}
                <PublicRoute component={AboutIndex} path="/about" exact restricted={false} />
                {/* Contact */}
                <PublicRoute component={ContactIndex} path="/contact" exact restricted={false} />
                {/* Service */}
                <PublicRoute component={ServiceIndex} path="/service" exact restricted={false} />
            </Switch>
        </>
    );
}

export default AppRouter;