import React from 'react';
import MasterLayout from './components/master_layout';
import './layout.css';

const LayoutIndex = ({ children, }) => {
    return (
        <>
            <MasterLayout>
                {children}
            </MasterLayout>
        </>
    );
}

export default LayoutIndex;