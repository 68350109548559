import './App.css';
import { ConfigProvider } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth from './Auth';

function App() {
  return (
    <>
      <ConfigProvider
      theme={{
        token : {
          colorPrimary : '#fa8c16',
        }
      }}
      >
        <div className="App">
          <Router>
            <Auth />
          </Router>
        </div>
      </ConfigProvider>
    </>
  );
}

export default App;
