import Supabase from "./supabase";

class Project extends Supabase {
    id;
    title;
    description;
    sequence;
    image_url;
    sub_images = [];

    constructor(id, title, description, sequence, image_url, sub_images){
        super();
        this.id = id;
        this.title = title;
        this.description = description;
        this.sequence = sequence;
        this.image_url = image_url;
        this.sub_images = sub_images;
    }

    async fetchProjects(){
        const { data, error } = await this.supabase
        .from('projects')
        .select(`id, title, image, description, sequence, project_images (id, image) `);

        const projects = data?.map(async (project, index) => {
            const { id, title, image, description, sequence, project_images, } = project;
            const image_url = await this.fetchImage(image);

            let sub_image_urls = [];
            for(const project_image of project_images){
                const { image : sub_image } = project_image ?? {};
                const sub_image_url = await this.fetchImage(sub_image);
                sub_image_urls?.push(sub_image_url);
            }

            return new Project(id, title, description, sequence, image_url, sub_image_urls);
        })

        return Promise.all(projects) ?? [];
    }

    async fetchProjectById(){
        const { data, error } = await this.supabase
        .from('projects')
        .select(`id, title, image, description, sequence, project_images (id, image) `)
        .eq('id', this.id)
        .maybeSingle();

        const { id, title, description, sequence, image, project_images, } = data;
        const image_url = await this.fetchImage(image);

        let sub_image_urls = [];
        for(const project_image of project_images){
            const { image : sub_image } = project_image ?? {};
            const sub_image_url = await this.fetchImage(sub_image);
            sub_image_urls?.push(sub_image_url);
        }

        const project = new Project(id, title, description, sequence, image_url, sub_image_urls);

        return project;
    }

    async fetchImage(image){
        const { data, error } = this.supabase
        .storage
        .from('projects')
        .getPublicUrl(`${image}`);
        
        return data?.publicUrl;
    }
}

export default Project;