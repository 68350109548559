import { createClient } from "@supabase/supabase-js";

class Supabase {
    supabase;
    constructor(){
        const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_API_KEY);
        this.supabase = supabase;
    }
}

export default Supabase;