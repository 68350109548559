import { Col, Divider, Row, Space } from 'antd';
import React from 'react';
import AboutTitle from './about_title';
import globalHelper from '../../../helpers/functions/global_helper';

const AboutMissionVision = () => {
    return (
        <>
            <div>
                <div style={{ paddingInline : (globalHelper?.isMobileScreen() ? 12 : 42), paddingBlock : 24, }}>
                    <Row justify={'center'}>
                        <Col xs={24} sm={18}>
                            <div>
                                <Space direction='vertical' >
                                    <div>
                                        <AboutTitle sequence={0} title={'Vision'}  image={'/icons/vision.svg'} description={"Our vision at Evron Space Solution Sdn Bhd is to lead Malaysia's maintenance and renovation industry by setting new standards of excellence and reliability."}/>
                                    </div>
                                    <Divider />
                                    <div>
                                    <AboutTitle sequence={1} title={'Mission'} image={'/icons/mission.svg'} description={"At Evron Space Solution, our mission is to deliver unparalleled maintenance and renovation solutions, enhancing the efficiency, safety, and longevity of our clients' premises. We strive to exceed expectations through professionalism, integrity, and customer satisfaction."} />
                                    </div>
                                    <Divider />
                                    <div>
                                        <AboutTitle sequence={2} title={'Core Value'} image={'/icons/diamond.svg'} description={(
                                            <>
                                                <Space direction='vertical' size={12}>
                                                    <div>
                                                        <div>
                                                            <span style={{ fontWeight : 500, color : '#000', fontSize : 16, }}>Innovation</span>
                                                        </div>
                                                        <div>
                                                            <span>We embrace innovation as a driving force behind our success. We continuously seek new and improved ways to deliver value to our clients and stay ahead of industry trends.</span>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div>
                                                            <span style={{ fontWeight : 500, color : '#000', fontSize : 16, }}>Excellence</span>
                                                        </div>
                                                        <div>
                                                            <span>We are committed to excellence in everything we do. From the quality of our workmanship to our customer service, we strive for excellence in every aspect of our business.</span>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div>
                                                            <span style={{ fontWeight : 500, color : '#000', fontSize : 16, }}>Integrity</span>
                                                        </div>
                                                        <div>
                                                            <span>Integrity is the cornerstone of our business. We conduct ourselves with honesty, transparency, and ethical behavior in all our interactions, building trust and credibility with our clients and partners.</span>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div>
                                                            <span style={{ fontWeight : 500, color : '#000', fontSize : 16, }}>Collaboration</span>
                                                        </div>
                                                        <div>
                                                            <span>We believe in the power of collaboration and teamwork. By working together with our clients, partners, and employees, we can achieve more and deliver better outcomes for all stakeholders.</span>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div>
                                                            <span style={{ fontWeight : 500, color : '#000', fontSize : 16, }}>Customer Focus</span>
                                                        </div>
                                                        <div>
                                                            <span>Our customers are at the heart of everything we do. We listen to their needs, understand their challenges, and tailor our solutions to meet their specific requirements. Customer satisfaction is our top priority, and we go above and beyond to exceed their expectations.</span>
                                                        </div>
                                                    </div>
                                                </Space>
                                            </>
                                        )} />
                                    </div>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default AboutMissionVision;