import React from 'react';
import HeaderItem from '../../../helpers/components/header_item';
import ContainerComponent from '../../../helpers/components/container_component';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Col, Divider, Image, Row, Space } from 'antd';
import globalHelper from '../../../helpers/functions/global_helper';
import ServiceList from './service_list';

const ServiceLayout = () => {

    return (
        <>
            <HeaderItem>
                <ContainerComponent span={24} top={24} bottom={62}>
                    <div>
                        <EntranceMotion>
                            <div>
                                <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                                    Our Services
                                </span>
                            </div>
                        </EntranceMotion>
                        <EntranceMotion delay={(1 * 0.1)} style={{ marginTop : 8, }}>
                            <Row justify={'center'}>
                                <Col xs={24} sm={18}>
                                    <div>
                                        <span style={{ color : 'var(--secondary-text-color)', fontSize : 18, }}>
                                        At Evron Space Solution, we offer comprehensive maintenance and renovation solutions tailored to meet the diverse needs of our clients. Whether you require routine upkeep or a complete transformation of your premises, we have the expertise and resources to deliver exceptional results. Our core services include:
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </EntranceMotion>
                    </div>

                    <div style={{ marginTop : 62, }}>
                        <ServiceList />
                    </div>
                </ContainerComponent>
            </HeaderItem>
        </>
    );
}

export default ServiceLayout;