import React from 'react';
import { Form, Input, Row, Col } from 'antd';

const { TextArea } = Input;
const ContactForm = ({ form, }) => {
    return (
        <>
            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <Form.Item name={"first_name"} label={"First Name"}>
                        <Input placeholder='First Name' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={"last_name"} label={"Last Name"}>
                        <Input placeholder='Last Name' />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="email" label={"Email"}>
                <Input placeholder='you@company.com' />
            </Form.Item>
            <Form.Item name="phone_number" label={"Phone Number"}>
                <Input />
            </Form.Item>
            <Form.Item name="message" label={"Message"}>
                <TextArea placeholder='Leave us a message' style={{ resize : 'none', }} rows={4} />
            </Form.Item>
        </>
    );
}

export default ContactForm;