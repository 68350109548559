import React, { useEffect, useState } from 'react';
import globalHelper from '../../../helpers/functions/global_helper';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Rate, Space } from 'antd';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const HomeTestimonial = () => {
    const [state, setState] = useState({
        width : 1024,
        height : 1024
    })

    const testimonials = [{
        title : "Renovating our store was a breeze with their team. The new design is not only aesthetically pleasing but also highly functional. Sales have increased since the renovation!"
    },{
        title : 'They turned our outdated store into a modern, customer-friendly environment. The process was smooth, and the results are fantastic. We’re seeing more foot traffic now.'
    },{
        title : 'Our store looks brand new! Evron team was professional and completed the project on schedule. Highly recommend their services!'
    },{
        title : 'The store renovation exceeded our expectations. The layout is now more efficient, and customers have noticed the positive changes. Great job!'
    },{
        title : 'We hired them to revamp our convenience store, and they did an outstanding job. The new design is both attractive and practical, making shopping easier for our customers.'
    },{
        title : "The team was fantastic from start to finish. Our store renovation was handled with care and attention to detail. We couldn't be happier with the results."
    },{
        title : 'Our store needed a major upgrade, and they delivered. The new layout is much more efficient, and our customers love the updated look.'
    }]

    const CustomArrow = (props) => {
        const { className, style, onClick } = props;
        const is_right = className?.includes('slick-next');
        return (
            <>
                <div
                className={`${className} card-shadow`}
                style={{ ...style, 
                    display: "block", 
                    width: 40, 
                    height : 40, 
                    color: '#fff',
                    fontSize:12,
                    fontWeight:'bold', 
                    background : '#2A5595', 
                    borderRadius:'50%', 
                    display:'flex', 
                    justifyContent:'center', 
                    alignItems:'center',
                    zIndex : 100,
                }}
                onClick={onClick}
                >
                    <div>
                        {is_right ? <RightOutlined /> : <LeftOutlined />}
                    </div>
                </div>
            </>
        )
    }

    const settings = {
        dots: false,
        autoplay : true,
        autoplaySpeed : 2000,
        infinite: true,
        speed: 500,
        slidesToShow: state?.width <= 540 ? 1 : state?.width <= 960 ? 2 : 3,
        // centerMode: true,
        centerPadding: "60px",
        slidesToScroll: state?.width <= 540 ? 1 : state?.width <= 960 ? 2 : 3,
        // nextArrow: <CustomArrow />,
        // prevArrow : <CustomArrow />
    };

    useEffect(()=> {
        setState(state => ({...state, width : window.innerWidth, height : window.innerHeight}));
        window.addEventListener('resize', ()=> {
            setState(state => ({...state, width : window.innerWidth, height : window.innerHeight}));
        })
     }, []);

    return (
        <>
            <div style={{ paddingTop : 20, paddingBottom : 40, paddingInline : 40, }}>
                <div>
                    <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                        Hear from our Clients!
                    </span>
                </div>

                <div style={{ marginTop : 40, }}>
                    <Slider {...settings} className="testimonial_container" >
                        {
                            testimonials?.map((testimonial, index) => {
                                const { title } = testimonial;
                                return (
                                    <div key={index} style={{paddingInline : 20,}}>
                                        <Card
                                        // className='card-shadow'
                                        bordered={false}
                                        style={{borderRadius:8, background : '#F3F6FA', marginInline : 20,}}
                                        >
                                            <div>
                                                <Rate disabled allowHalf value={5} />
                                                <div>
                                                    <span style={{whiteSpace:'pre-line', fontSize:'1rem', lineHeight:'1.5rem', color:'#2D333A'}}>
                                                        {title}
                                                    </span>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default HomeTestimonial;