import { Col, Image, Row, Space } from 'antd';
import React from 'react';
import globalHelper from '../../../helpers/functions/global_helper';

const AboutWhyChooseUs = () => {
    const why_choose_us = [{
        title : 'Expertise',
        description : "With years of experience and a team of skilled professionals, Evron Space Solution excels in providing top-notch maintenance and renovation services tailored to your specific needs. Our expertise ensures high-quality results that meet and exceed your expectations.",
    },{
        title : 'Comprehensive Solutions',
        description : "From preventive maintenance to repair services and complete renovation projects, we offer a comprehensive range of solutions to address all your maintenance and renovation needs under one roof. Our integrated approach saves you time, effort, and resources, providing convenience and peace of mind.",
    },{
        title : 'Customer-Centric Approach',
        description : "Your satisfaction is our priority. We listen to your needs, understand your challenges, and work closely with you to develop customized solutions that best fit your requirements. Our customer-centric approach ensures a positive experience and long-term partnerships built on trust and reliability.",
    },{
        title : 'Quality Assurance',
        description : "We are committed to delivering the highest standards of quality in every project we undertake. From the materials we use to the craftsmanship we employ, we uphold strict quality control measures to ensure exceptional results that stand the test of time.",
    }]
    return (
        <>
            <div style={{ marginTop : 24,  }}>
                <div style={{ padding : 24, }}>
                    <span style={{ fontFamily : 'inter', fontSize : (globalHelper?.isMobileScreen() ? '1.5rem' : '2rem'), fontWeight : 900, }}>
                        Why Choose Us
                    </span>

                    <div style={{ marginTop : 8, }}>
                        <span style={{ color : 'var(--secondary-text-color)', fontSize : 18, }}>
                            When it comes to maintenance and renovation solutions, choosing the right partner can make all the difference. At Evron Space Solution, we understand the importance of selecting a reliable and experienced provider who can deliver results that meet your expectations and exceed your requirements. Here's why you should choose us for all your maintenance and renovation needs:
                        </span>
                    </div>
                </div>

                <div>
                    <Row>
                        {
                            why_choose_us?.map((x, index) => {
                                const { title, description, } = x;
                                const is_odd = index % 2 === 0;
                                const bg_color = is_odd ? '#fff7e6' : '#fff';
                                return (
                                    <Col xs={24} sm={6} key={index}>
                                        <div style={{ background : bg_color, height : '100%', }}>
                                            <div style={{ textAlign : 'start', padding : 24, }}>
                                                <div>
                                                    <span style={{ fontFamily : 'inter', fontSize : 20, fontWeight : 600, }}>
                                                        {title}
                                                    </span>
                                                </div>
                                                <div style={{ marginTop : 12, }}>
                                                    <span style={{ fontFamily : 'inter', fontSize : 16, color : 'var(--secondary-text-color)', }}>
                                                        {description}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </div>
        </>
    );
}

export default AboutWhyChooseUs;