import React from 'react';
import HomeLayout from './components/home_layout';

const HomeIndex = () => {
    return (
        <>
            <HomeLayout />
        </>
    );
}

export default HomeIndex;